import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Cart } from 'src/app/models/cart';
import { convertSnaps } from '../db-utils';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { Storage } from '@ionic/storage';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { UtilsService } from '../utils/utils.service';
import { LabelService } from '../label/label.service';
import { ConfigService } from '../config/config.service';
import * as moment from 'moment';
import { VendorService } from '../vendor/vendor.service';
import { Router } from '@angular/router';
import { SharedNewService } from '../shared-new/shared-new.service';
import { RepeatAddonChoicePage } from 'src/app/pages/select-addon/repeat-addon-choice/repeat-addon-choice.page';
import { SelectAddonPage } from 'src/app/pages/select-addon/select-addon.page';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    cartProductsUpdated = new Subject<Cart[]>();
    cartProducts = [];
    userRef = this.afs.collection('users');
    currentCategory = null;
    loading: HTMLIonLoadingElement;
    exclusiveVendorId = '';
    cartLoader: any;
    alreadyAddedAlertPresent: any;
    constructor(
        private afs: AngularFirestore,
        private logglyService: LogglyLoggerService,
        private storage: Storage,
        private alertController: AlertController,
        private labelService: LabelService,
        private loadingController: LoadingController,
        private utilsService: UtilsService,
        private configService: ConfigService,
        private vendorService: VendorService,
        private router: Router,
        private sharedNewService: SharedNewService,
        private modalController: ModalController) { }


    async setCartProducts() {
        try {
            const role = await this.storage.get('userRole');
            if (role === 'user') {
                const uid = await this.storage.get('uid');
                if (uid && uid !== undefined) {
                    const cartRef = this.afs.collection('users').doc(uid).collection('cart');
                    const cartProducts = await cartRef.snapshotChanges().pipe(
                        map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();


                    this.cartProducts = await this.getCartProductsFromStorage(cartProducts);
                    this.cartProductsUpdated.next([...this.cartProducts]);

                    this.setExclusivity();
                    console.log('cart in cartService ->', this.cartProducts);
					return this.cartProducts;
                }
            } else {
                const cartStorage = await this.storage.get('cart');
                console.log('cartStorage', cartStorage);
                if(cartStorage && cartStorage.length) {
                    this.cartProducts = cartStorage;
                    this.cartProductsUpdated.next([...this.cartProducts]);
                    this.setExclusivity();
					return this.cartProducts;
                }
            }

        } catch (err) {
            console.dir(err);
            err['location'] = 'cart-service:setUserCartProducts';
            this.logglyService.log(err);
        }
    }

    async getCartProductsFromStorage(dbCartProducts) {
        const cartStorage = await this.storage.get('cart');
        if(cartStorage && cartStorage.length) {
            for (const cartStorageObj of cartStorage) {
                if(dbCartProducts && dbCartProducts.length) {
                    const sameProducts = dbCartProducts.filter(dbCart => dbCart.productId === cartStorageObj.productId);
                    let isMultipleProduct = false;
                    if (sameProducts.length) {
                        if(!cartStorageObj.pack) {
                            isMultipleProduct = true;
                        } else {
                            sameProducts.forEach(p => {
                                if(p.pack && (p.pack.weight === cartStorageObj.pack.weight)) {
                                    isMultipleProduct = true;
                                }
                            })
                        }
                    }
                    if(!isMultipleProduct) {
                        dbCartProducts.push(cartStorageObj);
                    }
                } else {
                    dbCartProducts.push(cartStorageObj);
                }
            }
        }
        return dbCartProducts;
    }

    async fetchCartProducts() {
        const uid = await this.storage.get('uid');
        const cartRef = this.afs.collection('users').doc(uid).collection('cart');
        const cartProducts = await cartRef.snapshotChanges().pipe(
            map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
        return cartProducts;
    }

    getCartProductsListener() {
        return this.cartProductsUpdated.asObservable();
    }
    getCartProducts() {
        return this.cartProducts;
    }

    async updateCartProduct(cartID, qty) {
        const uid = await this.storage.get('uid');
        if(!uid) return;
        const index = this.cartProducts.findIndex(c => c.id === cartID);
        let updatedData: any = {
            quantity: qty
        }
        if('addOns' in this.cartProducts[index]) {
            updatedData = {...this.cartProducts[index]};
            if(updatedData.cartUpdateTimer) {
                delete updatedData.cartUpdateTimer;
            }
        }
        await this.afs.collection('users').doc(uid).collection('cart').doc(cartID).update(updatedData);
    }

    async deleteCartProduct(cartID) {
        const uid = await this.storage.get('uid');
        const index = this.cartProducts.findIndex(c => c.id === cartID);
        this.cartProducts.splice(index, 1);
        this.cartProductsUpdated.next([...this.cartProducts]);
        if (!this.cartProducts.length) {
            this.exclusiveVendorId = '';
        }
        if(!uid) {
            this.storage.set('cart', [...this.cartProducts]);
            return;
        };
        await this.afs.collection('users').doc(uid).collection('cart').doc(cartID).delete();
    }

    async updateCartState(cartID, qty) {
        const index = this.cartProducts.findIndex(c => c.id === cartID);
        const updatedProductWithAddonsIfAny = await this.repeatOrAddNewAddons(this.cartProducts[index], qty);
        console.log('updatedProductWithAddonsIfAny', updatedProductWithAddonsIfAny);
        if(!updatedProductWithAddonsIfAny) {
            return false;
        }
        this.cartProducts[index] = updatedProductWithAddonsIfAny;
        this.cartProducts[index].quantity = qty;
        this.cartProductsUpdated.next([...this.cartProducts]);
        const uid = await this.storage.get('uid');
        if(!uid) {
            this.storage.set('cart', [...this.cartProducts]);
        }
        return true;
    }

    async repeatOrAddNewAddons(product, updatedQty) {
        if(!product.addOns || !Object.keys(product.addOns.options).length) {
            return product;
        }
        const lastAddonIndex = Object.keys(product.addOns.options).length - 1;
        const lastAddon = product.addOns.options[lastAddonIndex];
        if(product.quantity < updatedQty) {
            const choice = await this.getRepeatOrAddNewAddonsChoice({
                name: product.name, lastAddon
            });
            switch(choice) {
                case 'repeat':
                    let addOnsPrice = 0;
                    lastAddon.forEach(addOn => {
                        addOn.quantity += 1;
                        addOn.options.forEach(option => {
                            addOnsPrice += option.price;
                        })
                    });
                    product.addOns.totalPrice += addOnsPrice;
                    product.addOns.options[lastAddonIndex] = lastAddon;
                    // product.price += addOnsPrice;
                    break;

                case 'new':
                    const addOns = await this.getAddonsIfAny({...product, quantity: 1});
                    if(addOns.selectedOptions.length > 0) { 
                        product.addOns.options[Object.keys(product.addOns.options).length] = addOns.selectedOptions;
                        product.addOns.totalPrice += addOns.totalPrice;
                        // product.price += addOns.totalPrice;
                        break;
                    } else {
                        return null;
                    }

                case '':
                    return null; 
            }
        } else {
            let lastAddonPrice = 0;
            lastAddon.forEach(addOn => {
                addOn.options.forEach(option => {
                    lastAddonPrice += option.price;
                })
            });
            lastAddon[0].quantity -= 1;
            if(lastAddon[0].quantity === 0) {
                delete product.addOns.options[lastAddonIndex];
            }
            product.addOns.totalPrice -= lastAddonPrice;
            // product.price -= lastAddonPrice;
        }
        return product;
    }

    async getRepeatOrAddNewAddonsChoice(data) {
        return new Promise<string>(async (resolve) => {
            const modal = await this.modalController.create({
                component: RepeatAddonChoicePage,
                cssClass: 'repeat-addon-modal',
                componentProps: {...data}
            });
            modal.onDidDismiss().then(res => {
                if(res && res.data && res.data.choice) {
                    resolve(res.data.choice);
                } else {
                    resolve('');
                }
            })
            await modal.present();
        });
    }

    async presentCartLoader() {
        this.cartLoader = await this.loadingController.create({
            cssClass: 'cart-loader',
            spinner: null,
            duration: 1000
        });
        await this.cartLoader.present();
    }

    async addProductToCart(cartObj) {

        await this.presentCartLoader();

        let action = 'add';

        if(this.productAlreadyAddedToCart(cartObj)) {
            action = 'not_add';
            this.alreadyAddedAlert();
        }

        //check exclusivity of category
        if(action === 'add') {
            action = await this.checkCartExclusivity(cartObj);
            if (action === 'add') {
                // check addOns
                if(!('addOns' in cartObj)) {
                    const addOns = await this.getAddonsIfAny(cartObj);
                    console.log('addOns', addOns);
                    if(addOns.required) {
                        if(addOns.selectedOptions.length > 0) {
                            cartObj.addOns = {
                                totalPrice: addOns.totalPrice,
                                options: {0: addOns.selectedOptions}
                            };
                            // cartObj.price += addOns.totalPrice; 
                        } else {
                            return;
                        }
                    }
                }
                console.log('cartObj', cartObj);
            
                const uid = await this.storage.get('uid');
                let cartId = '';
                if(uid) {
                   const cartRef = await this.afs.collection('users').doc(uid).collection('cart').add(cartObj);
                   cartId = cartRef.id;
                }
                if(!cartId) {
                    cartId = this.getCartIdIfNoLogin(cartObj);  
                }
                this.cartProducts.push({ id: cartId, ...cartObj });
                this.cartProductsUpdated.next([...this.cartProducts]);
                if(!uid) {
                    this.storage.set('cart', [...this.cartProducts]);
                }
                return cartId;
            }
        }

        if(this.cartLoader) {
            this.cartLoader.dismiss();
        }

        return;

    }

    async getAddonsIfAny(cartObj) {
        return new Promise<{totalPrice: number, selectedOptions: any[], required: boolean}>(async (resolve, reject) => {
            let addOns = {totalPrice: 0, selectedOptions: [], required: false};
            if(cartObj.templateId) {
                await this.presentLoading();
                const template = await this.sharedNewService.getTemplateData(cartObj.templateId);
                this.loading.dismiss();
                if(!template) {
                    resolve(addOns);
                    return;
                }
                const modal = await this.modalController.create({
                    component: SelectAddonPage,
                    // cssClass: 'region-modal',
                    componentProps: {
                        item: {
                            name: cartObj.name,
                            price: cartObj.price,
                            id: cartObj.productId,
                            templateId: cartObj.templateId,
                            description: cartObj.prodShortDesc,
                            mrp: cartObj.mrpPrice,
                            quantity: cartObj.quantity
                        },
                        template
                    }
                });
                modal.onDidDismiss().then(res => {
                    if(res && res.data && !res.data.closed) {
                        addOns = res.data;
                    }
                    addOns.required = true;
                    resolve(addOns);
                });
                await modal.present();
            } else {
                resolve(addOns);
            }
        });
    }

    getCartIdIfNoLogin(cartObj) {
        if(!('pack' in cartObj)) {
            return cartObj.productId;
        } else {
            return `${cartObj.productId}-variant${cartObj.pack.weight}`;
        }
    }

    async alreadyAddedAlert() {
        if(!this.alreadyAddedAlertPresent) {
            const alert = await this.alertController.create({
                message: this.labelService.labels['PRODUCT_DETAILS']['already_added_to_cart'],
                buttons: [{
                    text: this.labelService.labels['PRODUCT_DETAILS']['continue_alert_btn'],
                    handler: () => {}
                }, {
                    text: this.labelService.labels['PRODUCT_DETAILS']['go_to_cart_alert_btn'],
                    handler: () => {
                        this.router.navigate(['user-cart']);
                    }
                }]
            });
            this.alreadyAddedAlertPresent = true;
            await alert.present();
        }
        setTimeout(() => {
            this.alreadyAddedAlertPresent = false;
        }, 1000);
    }

    productAlreadyAddedToCart(cartObj) {
        let added = false;
        if('pack' in cartObj) {
            added = this.cartProducts.some(c => c.productId === cartObj.productId && c.pack.weight === cartObj.pack.weight);
        } else {
            added = this.cartProducts.some(c => c.productId === cartObj.productId);
        }
        return added;
    }

    getCartPrice(cartProducts) {
        let price = 0;
        for (const pdt of cartProducts) {
            if (pdt.status && (pdt.totalQty === '0' && !pdt.stopWhenNoQty || pdt.totalQty !== '0')) {
                price += (pdt.price * pdt.quantity) + ('addOns' in pdt ? pdt.addOns.totalPrice : 0);
            }
        }
        return price;
    }

    removeHtml(desc) {
        const tagsRemoved = desc.replace(/(<([^>]+)>)/ig, '');
        const plainTxt = document.createElement('textarea');
        plainTxt.innerHTML = tagsRemoved;
        return plainTxt.value;
    }

    isProductInCart(product) {
        if (!product.hasOwnProperty('cartId') || product.cartId === '') {
            return false;
        } else {
            return this.cartProducts.some(c => c.id === product.cartId);
        }
    }

    async checkCartAvailability(inactiveByVendor?: boolean) {
        if(inactiveByVendor) {
            this.presentAlert('', `${this.labelService.labels['SHARED']['vendor_product_inactive']}`);
            return false;
        }
        const role = await this.storage.get('userRole');
        if (role === 'admin') {
            this.presentAlert('', 'Cart is available only for user not for admin.');
            return false;
        } else if (role === 'deliveryAgent') {
            this.presentAlert('', 'Cart is available only for user not for delivery agent.');
            return false;
        } else {
            return true;
        }
    }

    async presentLoading() {
        this.loading = await this.loadingController.create({
            message: this.labelService.labels['SHARED']['please_wait'],
        });
        await this.loading.present();
    }

    async presentAlert(subHeader: string, msg: string) {
        const alert = await this.alertController.create({
            subHeader: subHeader,
            message: msg,
            buttons: [this.labelService.labels['SHARED']['ok']],
            cssClass: 'alert-msg-w-header'
        });

        await alert.present();
    }

    async resetCart() {
        return new Promise(async (resolve) => {
            const batch = this.afs.firestore.batch();
            const uid = await this.storage.get('uid');
            const cartRef: any = this.afs.collection('users').doc(uid).collection('cart');
            for (const product of this.cartProducts) {
                batch.delete(cartRef.doc(product.id).ref);
            }
            batch.commit().then(() => {
                this.cartProducts = [];
                this.cartProductsUpdated.next([]);
                resolve(true);
            }).catch((error) => {
                console.log(error);
                resolve(false);
            });
        });
    }

    async checkCartExclusivity(cartObj) {
        return new Promise<string>(async (resolve, reject) => {

            if(!cartObj.vendorId && this.exclusiveVendorId) {
                console.log('no vendorId but cart is exc');
                const res = await this.showReplaceAlert();
                if(res === 'add') {
                    this.exclusiveVendorId = '';
                }
                resolve(res);
            } else if(!cartObj.vendorId && !this.exclusiveVendorId) {
                console.log('no vendorid and cart is not exc');
                resolve('add');
            } else {
                const exclusiveVendors = this.vendorService.vendorSettings.exclusiveVendors;
                if(exclusiveVendors.length) {
                    if(!this.cartProducts.length) {
                        if(cartObj.vendorId && exclusiveVendors.includes(cartObj.vendorId)) {
                            console.log('product is exc and no product in cart');
                            this.exclusiveVendorId = cartObj.vendorId;
                        }
                        resolve('add');
                    } else {
                        if(this.exclusiveVendorId === cartObj.vendorId) {
                            console.log('same vendor product');
                            resolve('add');
                        } else {
                            const isExclusive = exclusiveVendors.includes(cartObj.vendorId);
                            if(isExclusive) {
                                console.log('product is exc and cart has products');
                                const res = await this.showReplaceAlert();
                                if(res === 'add') {
                                    this.exclusiveVendorId = cartObj.vendorId;
                                }
                                resolve(res);
                            } else {
                                console.log('product is not exc');
                                if(this.exclusiveVendorId) {
                                    console.log('but cart is exc');
                                    const res = await this.showReplaceAlert();
                                    if(res === 'add') {
                                        this.exclusiveVendorId = '';
                                    }
                                    resolve(res);
                                } else {
                                    console.log('and cart is also not exc');
                                    this.exclusiveVendorId = '';
                                    resolve('add');
                                }
                            }
                        }
                    }
                } else {
                    console.log('no exc vendors');
                    this.exclusiveVendorId = '';
                    resolve('add');
                }
            }

            console.log('this.exclusiveVendorId', this.exclusiveVendorId);
        });
    }

    async showReplaceAlert() {
        return new Promise<string>(async (resolve) => {
            const confirm = await this.replaceCartAlert();
            if (confirm) {
                await this.presentLoading();
                const res = await this.resetCart();
                this.loading.dismiss();
                if (res) {
                    resolve('add');
                } else {
                    this.presentAlert(this.labelService.labels['SHARED']['oops'], this.labelService.labels['SHARED']['some_issue_msg']);
                    resolve('not_add');
                }
            } else {
                resolve('not_add');
            }
        });
    }

    async replaceCartAlert(): Promise<boolean> {
        return new Promise(async (resolve) => {
            const alert = await this.alertController.create({
                subHeader: this.labelService.labels['CART_SERVICE']['replace_alert_header'],
                message: this.labelService.labels['CART_SERVICE']['replace_alert_msg'],
                cssClass: 'alert-msg-w-header',
                buttons: [
                    {
                        text: this.labelService.labels['CART_SERVICE']['no'],
                        handler: () => resolve(false)
                    },
                    {
                        text: this.labelService.labels['CART_SERVICE']['yes'],
                        handler: () => resolve(true)
                    }
                ]
            });
            await alert.present();
        });
    }

    async setExclusivity() {
        // let exclusiveObj = await this.storage.get('exclusiveObj');
        // if (exclusiveObj) {
        //     exclusiveObj['exclusivity'] = exclusiveObj.category;
        //     this.currentCategory = exclusiveObj.categoryId;
        //     this.storage.set('exclusiveObj', exclusiveObj);
        // }
        let cartVendorId = '';
        this.cartProducts.forEach(product => {
            if(product.vendorId) {
                cartVendorId = product.vendorId;
            }
        });
        const exclusiveVendors = await this.vendorService.getAllExclusiveVendors();
        if(exclusiveVendors.length) {
            const isExclusive = exclusiveVendors.includes(cartVendorId);
            if(isExclusive) {
                this.exclusiveVendorId = cartVendorId;
            }
        } else {
            this.exclusiveVendorId = '';
        }
    }

    getCartObj(product, productID, quantity?) {
        let cartObj = {
            name: product.prodName,
            quantity: quantity || 1,
            img: product.coverPic,
            productId: productID,
            commentMsg: '',
            commentImgs: [],
            maxQty: product.maxQty ? product.maxQty : 0,
            minQty: product.minQty ? product.minQty : 1,
            gst: product.gst ? product.gst : 0,
            status: typeof product.status !== 'undefined' ? product.status : true,
            stopWhenNoQty: product.hasOwnProperty('stopWhenNoQty') && typeof product.stopWhenNoQty !== 'undefined' ? product.stopWhenNoQty : false,
            totalQty: product.productQty ? product.productQty : '',
            hsn: product.hsnCode ? product.hsnCode : '',
            sku: product.productCode ? product.productCode : '',
            barcode: product.barcode ? product.barcode : '',
            shippingWt: product.shippingWeight || 0,
            barcodeNo: product.barcodeNo || '',
            gstExclusive: product.gstExclusive || false,
            extraCharges: ('extraCharges' in product) && (typeof product.extraCharges === 'object') && product.extraCharges.active ? product.extraCharges : { charge: 0 },
            isCod: 'isCod' in product ? product.isCod : true,
            vendorId: product.vendorId || '',
            priceSlabs: 'priceSlabs' in product ? {active: product.priceSlabs.active} : {active: false},
            templateId: product.templateId || ''

        };
        let desc = this.removeHtml(product.prodDesc);
        cartObj['description'] = desc;
        if (product.discountedPrice && (product.discountedPrice !== product.prodPrice)) {
            cartObj['mrpPrice'] = product.prodPrice;
            cartObj['price'] = product.discountedPrice;
        } else {
            cartObj['price'] = product.prodPrice;
        }
        if (product.hasOwnProperty('color') && product.color.hasOwnProperty('name')) {
            cartObj['color'] = product.color;
        }

        cartObj = this.priceSlabsCheck(cartObj, product);

        return cartObj;
    }

    getPriceListCartObj(product: any, variantIndex: number = 0, quantity = 1) {
        let cartObj = {
            name: product.data.prodName,
            quantity: quantity,
            img: this.getCoverPic(product.data, variantIndex),
            description: product.data.priceList[variantIndex].weight,
            commentMsg: '',
            commentImgs: [],
            maxQty: product.data.maxQty ? product.data.maxQty : 0,
            minQty: product.data.minQty ? product.data.minQty : 1,
            gst: product.data.gst ? product.data.gst : 0,
            status: typeof product.data.status !== 'undefined' ? product.data.status : true,
            stopWhenNoQty: product.data.hasOwnProperty('stopWhenNoQty') && typeof product.data.stopWhenNoQty !== 'undefined' ? product.data.stopWhenNoQty : false,
            totalQty: product.data.priceList[variantIndex].totalQuantity ? product.data.priceList[variantIndex].totalQuantity : '',
            hsn: product.data.hsnCode ? product.data.hsnCode : '',
            sku: 'sku' in product.data.priceList[variantIndex] ? product.data.priceList[variantIndex].sku : product.data.productCode ? product.data.productCode : '',
            barcode: product.data.priceList[variantIndex].barcode ? product.data.priceList[variantIndex].barcode : '',
            shippingWt: product.data.priceList[variantIndex].shippingWeight || 0,
            barcodeNo: product.data.priceList[variantIndex].barcodeNo || '',
            gstExclusive: product.data.gstExclusive || false,
            extraCharges: ('extraCharges' in product.data) && (typeof product.data.extraCharges === 'object') && product.data.extraCharges.active ? product.data.extraCharges : { charge: 0 },
            isCod: 'isCod' in product.data ? product.data.isCod : true,
            vendorId: product.data.vendorId || '',
            priceSlabs: 'priceSlabs' in product.data ? {active: product.data.priceSlabs.active} : {active: false},
            templateId: product.data.templateId || '',
            pack: {
                weight: product.data.priceList[variantIndex].weight,
                variantType: product.data.variantType ? product.data.variantType : 'variant'
            }
        };
        if (product.data.variantType && product.data.variantType === 'pieces') {
            if (product.data.priceList[variantIndex].discountedPrice && product.data.priceList[variantIndex].discountedPrice !== product.data.priceList[variantIndex].price) {
                cartObj['mrpPrice'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj['price'] = product.data.priceList[variantIndex].discountedPrice * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['price'] = product.data.priceList[variantIndex].discountedPrice * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['perPcPrice'] = product.data.priceList[variantIndex].discountedPrice;
            } else {
                cartObj['price'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['price'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['perPcPrice'] = product.data.priceList[variantIndex].price;
            }
        } else {
            if (product.data.priceList[variantIndex].discountedPrice && product.data.priceList[variantIndex].discountedPrice !== product.data.priceList[variantIndex].price) {
                cartObj['mrpPrice'] = product.data.priceList[variantIndex].price;
                cartObj['price'] = product.data.priceList[variantIndex].discountedPrice;
                cartObj.pack['price'] = product.data.priceList[variantIndex].discountedPrice;
            } else {
                cartObj['price'] = product.data.priceList[variantIndex].price;
                cartObj.pack['price'] = product.data.priceList[variantIndex].price;
            }
        }

        if (product.data.hasOwnProperty('color') && product.data.color.hasOwnProperty('name')) {
            cartObj['color'] = product.data.color;
        }

        if (product.parentProductId) {
            cartObj['parentProductId'] = product.parentProductId;
            cartObj['productId'] = product.id;
        } else {
            cartObj['productId'] = product.id;
        }

        cartObj = this.priceSlabsCheck(cartObj, product.data);

        return cartObj;
    }

    getCoverPic(product, index) {
        const variant = product.priceList[index];
        return 'images' in variant && variant.images.length ? variant.images[0] : product.coverPic;
    }

    async updatedCartFromBackend(cartProducts: any[], page?: string) {
        return new Promise<any[]>(async (resolve, reject) => {
            try {
                const products = JSON.parse(JSON.stringify(cartProducts));
                let cartPdts = [];
                let pdtsIds = [];
                let subPdtIds = [];
                let pdts = [];
                let subPdts = [];
                let updatedCartPdts = [];
                let subscribedPdts = [];
                let membership = [];

                for (const p of products) {
                    if (p.orderType === 'subscription') {
                        subscribedPdts.push(p);
                    } else if (p.orderType === 'membership') {
                        membership.push(p)
                    } else {
                        cartPdts.push(p);
                        if (!p.parentProductId) {
                            pdtsIds.push(p.productId);
                        } else {
                            subPdtIds.push({
                                parentId: p.parentProductId,
                                pid: p.productId
                            });
                        }
                    }
                }

                if (pdtsIds.length) {
                    for (let index = 0; index < pdtsIds.length; index++) {
                        let productRef = await this.afs.collection('products').doc(pdtsIds[index]).valueChanges().pipe(first()).toPromise();
                        if (productRef) {
                            productRef['id'] = pdtsIds[index];
                            pdts.push(productRef);
                        }

                    }
                }
                if (subPdtIds.length) {
                    for (let index = 0; index < subPdtIds.length; index++) {
                        let subProductRef = await this.afs.collection('products').doc(subPdtIds[index].parentId).collection('options').doc(subPdtIds[index].pid).valueChanges().pipe(first()).toPromise();
                        if (subProductRef) {
                            subProductRef['id'] = subPdtIds[index].pid;
                            subPdts.push(subProductRef);
                        }
                    }
                }
                let allUpdatedPdts = pdts.concat(subPdts);
                if (allUpdatedPdts.length) {
                    updatedCartPdts = await this.getUpdatedPdts(allUpdatedPdts, cartPdts, page);
                }
                if (subscribedPdts.length > 0) {
                    updatedCartPdts = updatedCartPdts.concat(subscribedPdts);
                }
                if (membership.length > 0) {
                    updatedCartPdts = updatedCartPdts.concat(membership);
                }
                resolve(updatedCartPdts);

            } catch (error) {
                console.dir(error);
                error['location'] = 'cart-service:updatedCartFromBackend';
                this.logglyService.log(error);
                resolve([]);
            }
        });
    }

    getUpdatedPdts(pdts, cartPdts, page?: string) {
        return new Promise<any[]>(async (resolve) => {
            for (let c of cartPdts) {
                const productIndex = pdts.findIndex(p => p.id === c.productId);
                if(productIndex !== -1) {
                    const dbProduct = pdts[productIndex];
                    c.name = dbProduct.prodName;
                    c.maxQty = dbProduct.maxQty ? dbProduct.maxQty : 0;
                    c.minQty = dbProduct.minQty ? dbProduct.minQty : 1;
                    c.status = dbProduct.inactiveByVendor ? false : typeof dbProduct.status !== undefined ? dbProduct.status : true;
                    c.stopWhenNoQty = dbProduct.hasOwnProperty('stopWhenNoQty') && typeof dbProduct.stopWhenNoQty !== undefined ? dbProduct.stopWhenNoQty : false;
                    c.retailDiscount = dbProduct.hasOwnProperty('retailDiscount') ? dbProduct.retailDiscount : 0;
                    c.retailDiscountType = dbProduct.retailDiscountType || 'percentage';
                    c.extraCharges = ('extraCharges' in dbProduct) && (typeof dbProduct.extraCharges === 'object') && dbProduct.extraCharges.active ? dbProduct.extraCharges : { charge: 0 };
                    c.img = dbProduct.coverPic;
                    c.priceSlabs = 'priceSlabs' in dbProduct ? {active: dbProduct.priceSlabs.active} : {active: false}
                    let deal = await this.checkLimitedTimeDeal(dbProduct);
                    if (deal.dealAllowed) {
                        if (deal.discount > 0) {
                            c.dealDiscount = deal.discount;
                        }
                    }
                    if (!c.hasOwnProperty('pack')) {
                        c.totalQty = dbProduct.productQty ? dbProduct.productQty : '';
                        if (parseInt(dbProduct.productQty) && (c.quantity > parseInt(dbProduct.productQty))) {
                            c.quantity = parseInt(dbProduct.productQty);
                        }
                        if (dbProduct.discountedPrice && dbProduct.discountedPrice !== dbProduct.prodPrice) {
                            c.price = dbProduct.discountedPrice;
                            c.mrpPrice = dbProduct.prodPrice;
                        } else {
                            c.price = dbProduct.prodPrice;
                        }
                    } else {
                        if (c.pack.variantType !== 'pieces') {
                            dbProduct.priceList.forEach((pl) => {
                                if (pl.weight === c.pack.weight) {
                                    c.totalQty = pl.totalQuantity ? pl.totalQuantity : '';
                                    if (parseInt(pl.totalQuantity) && (c.quantity > parseInt(pl.totalQuantity))) {
                                        c.quantity = parseInt(pl.totalQuantity);
                                    }
                                    if (pl.discountedPrice && pl.discountedPrice !== pl.price) {
                                        c.price = pl.discountedPrice;
                                        c.mrpPrice = pl.price;
                                        c.pack.price = pl.price;
                                    } else {
                                        c.price = pl.price;
                                        c.pack.price = pl.price;
                                    }
                                }
                            })
                        } else {
                            dbProduct.priceList.forEach((pl) => {
                                if (pl.weight === c.pack.weight) {
                                    c.totalQty = pl.totalQuantity ? pl.totalQuantity : '';
                                    if (parseInt(pl.totalQuantity) && (c.quantity > parseInt(pl.totalQuantity))) {
                                        c.quantity = parseInt(pl.totalQuantity);
                                    }
                                    if (pl.discountedPrice && pl.discountedPrice !== pl.price) {
                                        c.price = pl.discountedPrice * parseInt(pl.weight);
                                        c.mrpPrice = pl.price * parseInt(pl.weight);
                                        c.pack.price = pl.discountedPrice * parseInt(pl.weight);
                                        c.pack.perPcPrice = pl.discountedPrice;
                                    } else {
                                        c.price = pl.price * parseInt(pl.weight);
                                        c.pack.price = pl.price * parseInt(pl.weight);
                                        c.pack.perPcPrice = pl.price;
                                    }
                                }
                            })
                        }
                    }
                    if((page && page === 'order-summary') && ('extraCharges' in c) && (c.extraCharges.charge > 0)) {
                        const charge = c.extraCharges.chargeAllQty ? c.extraCharges.charge : c.extraCharges.charge / c.quantity;
                        c.price += charge;
                        if('mrpPrice' in c) {
                            c.mrpPrice += charge;
                        }
                        if('pack' in c) {
                            c.pack.price += charge;
                        }
                    }

                    //update price according to price slabs
                    c = this.priceSlabsCheck(c, dbProduct);

                } else {
                    c.status = false;
                }
            }
            resolve(cartPdts);
        });

    }

    priceSlabsCheck(cartObj, product) {
        if ('priceSlabs' in product && product.priceSlabs.active) {
            if (product.isPriceList) {
                const variantSlabs = product.priceSlabs.variantSlabs || {};
                console.log('variantSlabs', variantSlabs);
                if (Object.keys(variantSlabs).length) {
                    if (Object.keys(variantSlabs).includes(cartObj.pack.weight)) {
                        const slabs = variantSlabs[cartObj.pack.weight];
                        console.log('slabs', slabs);
                        if (slabs.length) {
                            let finalRange: any = {};
                            for (const element of slabs) {
                                console.log('element', element);
                                if ((cartObj.quantity >= element.qty[0]) && (cartObj.quantity <= element.qty[1])) {
                                    finalRange = element;
                                    break;
                                }
                            }
                            if (Object.keys(finalRange).length) {
                                if (cartObj.pack.variantType === 'pieces') {
                                    cartObj.price = finalRange.price * parseInt(cartObj.pack.weight);
                                    cartObj.pack.price = finalRange.price * parseInt(cartObj.pack.weight);
                                    cartObj.mrpPrice = finalRange.mrp;
                                } else {
                                    cartObj.price = finalRange.price;
                                    cartObj.pack.price = finalRange.price;
                                    cartObj.mrpPrice = finalRange.mrp;
                                }
                            }
                        }
                    }
                }
            } else {
                const singleSlabs = product.priceSlabs.singleSlabs || {};
                if (Object.keys(singleSlabs).length) {
                    let finalRange: any = {};
                    for (const element of singleSlabs) {
                        if ((cartObj.quantity >= element.qty[0]) && (cartObj.quantity <= element.qty[1])) {
                            finalRange = element;
                            break;
                        }
                    }
                    if (Object.keys(finalRange).length) {
                        cartObj.price = finalRange.price;
                        cartObj.mrpPrice = finalRange.mrp;
                    }
                }
            }
        }

        return cartObj;
    }

    async checkLimitedTimeDeal(data) {
        if (data.hasOwnProperty('deal') && data.deal.isAllowed) {
            let discount = data.deal.discount;
            if('specificUsers' in data.deal && data.deal.specificUsers.active && data.deal.specificUsers.users && data.deal.specificUsers.users.length) {
                const uid = await this.storage.get('uid');
                const index = data.deal.specificUsers.users.findIndex(u => u.id === uid);
                if(index === -1) {
                    return {dealAllowed: false, discount: null};
                } else {
                    discount = data.deal.specificUsers.users[index].discount;
                }
            }
            const currentTime = moment();
            const startDate = moment(data.deal.start.date).format('YYYY-MM-DD');
            const startTime = moment(data.deal.start.time).format('HH:mm');
            const endDate = moment(data.deal.end.date).format('YYYY-MM-DD');
            const endTime = moment(data.deal.end.time).format('HH:mm');
            const startDateTime = moment(`${startDate} ${startTime}`);
            const endDateTime = moment(`${endDate} ${endTime}`);

            if (moment(currentTime).isBetween(startDateTime, endDateTime)) {

                return {dealAllowed: true, discount};
            } else {

                return {dealAllowed: false, discount: null};
            }
        } else {

            return {dealAllowed: false, discount: null};
        }
    }

    async compareCartWithUpdatedCart(cartProducts: any[], page?: string) {
        const priceForRetail = this.configService.environment.priceForRetail;
        const subRole = await this.storage.get('userSubRole');
        const products = cartProducts.filter(p => p.orderType !== 'free');
        const updatedCart = await this.updatedCartFromBackend(products, page);
        const updateFields = ['maxQty', 'minQty', 'price', 'status', 'gst', 'shippingWt'];

        updatedCart.map(el => {
            if (el.hasOwnProperty('dealDiscount')) {
                let actualPrice = el.mrpPrice ? el.mrpPrice : el.price;
                el.price = actualPrice - (actualPrice * (el.dealDiscount / 100));
                el.mrpPrice = actualPrice;
            }
            if (subRole && subRole === 'retailer' && priceForRetail) {
                let retailDiscount = el.retailDiscount ? el.retailDiscount : 0;
                let retailDiscountType = el.retailDiscountType || 'percentage';
                if (retailDiscount) {
                    let actualPrice = el.mrpPrice ? el.mrpPrice : el.price;
                    if(retailDiscountType === 'percentage') {
                        el.price = actualPrice - (actualPrice * (retailDiscount / 100));
                    } else {
                        el.price = actualPrice - retailDiscount;
                    }
                    el.mrpPrice = actualPrice;
                }
            }
            el.price = this.sharedNewService.getPriceBasedOnExhangeRate(el.price);
            el.mrpPrice = this.sharedNewService.getPriceBasedOnExhangeRate(el.mrpPrice);
        });

        let cartList = [];
        let cartUpdated = false;
        products.map(cart => {
            const index = updatedCart.findIndex(uc => uc.id === cart.id);
            if (index !== -1) {
                console.log('index present', cart.id);
                const updatedProduct = updatedCart[index];
                const { quantity, ...change } = updatedProduct;
                change['quantity'] = cart.quantity;
                for (const key of Object.keys(cart)) {
                    if(!cartUpdated && updateFields.includes(key)) {
                        cartUpdated = cart[key] !== change[key];
                    }
                    if(change.price !== cart.price) {
                        cart.priceStatus = {
                            status: change.price > cart.price ? 'increase' : 'decrease',
                            priceDifference: Math.abs(change.price - cart.price)
                        }
                    }
                    cart[key] = change[key];
                }
                cartList.push(cart);
            } else {
                console.log('index not present', cart.id);
                cartUpdated = true;
            }
        });
        return {cartList, cartUpdated};

    }

    async inventoryManagement(data) {
        return new Promise<boolean>(async (resolve, reject) => {
            let manageInventory = firebase.functions().httpsCallable('orders-manageInventory');
            manageInventory({products: data.products, orderId: data.orderId}).then((res) => {
                resolve(res.data.status);
            });
        });
    }

    async getFreeProductLimits() {
        return new Promise(async (resolve, reject) => {
            try {
                const uid = await this.storage.get('uid');
                let allLimits = [];
                const limitsRef = this.afs.collection('settings').doc('freeProductsLimit');
                const limitDoc: any = await this.afs.collection('settings').doc('freeProductsLimit').valueChanges().pipe(first()).toPromise();
                if(limitDoc && limitDoc.active) {
                    const limits = await limitsRef.collection('limits', ref => ref.where('active', '==', true))
                    .snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                    if(limits && limits.length) {
                        for (const limit of limits) {
                            const usage = await limitsRef.collection('limits').doc(limit.id).collection('usage', ref => ref.where('userId', '==', uid)).valueChanges().pipe(first()).toPromise();
                            if(usage && usage.length < limit.perUser) {
                                const product: any = await this.afs.collection('products').doc(limit.product.id).valueChanges().pipe(first()).toPromise();
                                if(product) {
                                    if(product.isPriceList && limit.product.type === 'variant') {
                                        if(product.priceList.length) {
                                            const variantIndex = product.priceList.findIndex(v => v.weight === limit.product.variant);
                                            if(variantIndex !== -1) {
                                                if(product.priceList[variantIndex].totalQuantity !== '0') {
                                                    const freeProduct: any = this.getPriceListCartObj({data: product, id: limit.product.id}, variantIndex);
                                                    freeProduct.price = 0;
                                                    freeProduct.pack.price = 0;
                                                    if(freeProduct.pack.variantType === 'pieces') {
                                                        freeProduct.pack.perPcPrice = 0;
                                                    }
                                                    allLimits.push({...limit, freeProduct});
                                                }
                                            }
                                        }
                                    } else {
                                        if(product.productQty !== '0') {
                                            const freeProduct: any = this.getCartObj(product, limit.product.id);
                                            freeProduct.price = 0;
                                            allLimits.push({...limit, freeProduct});
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if(allLimits.length) {
                    allLimits.sort((a, b) => {
                        return a.orderAmount < b.orderAmount ? 1 : -1;
                    });
                }
                resolve(allLimits);
            } catch (error) {
                console.log(error);
                resolve([]);
            }

        });
      }
}
