/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delivery-schedule-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./delivery-schedule-modal.page";
import * as i6 from "@angular/router";
import * as i7 from "@ionic/storage";
import * as i8 from "../services/label/label.service";
import * as i9 from "../services/config/config.service";
import * as i10 from "../services/user/user.service";
import * as i11 from "../services/shared/shared.service";
import * as i12 from "../services/multi-region/multi-region.service";
import * as i13 from "../services/cart/cart.service";
import * as i14 from "../services/order/order.service";
import * as i15 from "../services/admin-settings/admin-settings.service";
import * as i16 from "../services/delivery-settings/delivery-settings.service";
import * as i17 from "../services/multi-countries/multi-countries.service";
var styles_DeliveryScheduleModalPage = [i0.styles];
var RenderType_DeliveryScheduleModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_DeliveryScheduleModalPage, data: {} });
export { RenderType_DeliveryScheduleModalPage as RenderType_DeliveryScheduleModalPage };
function View_DeliveryScheduleModalPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "delivery-date-time"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dateSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "delivery-date-time"; var currVal_1 = _ck(_v, 3, 0, (_co.selectedDate === _v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit)); _ck(_v, 4, 0, currVal_2); }); }
function View_DeliveryScheduleModalPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "delivery-time-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.timeSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "deactiveTime": 0, "activeTime": 1 }), (_l()(), i1.ɵted(4, null, ["", " - ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "delivery-time-btn"; var currVal_1 = _ck(_v, 3, 0, _co.disableTime(_v.context.$implicit), (_co.selectedTime === _v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.start; var currVal_3 = _v.context.$implicit.end; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
export function View_DeliveryScheduleModalPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { content: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 21, "ion-list", [["class", "ion-no-padding"], ["lines", "none"]], null, null, null, i3.View_IonList_0, i3.RenderType_IonList)), i1.ɵdid(3, 49152, null, 0, i4.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "button", [["class", "cancelBTn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "flaticon-null-19"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "h4", [["class", "slotHead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Slot For Delivery"])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "ion-spinner", [["color", "primary"], ["id", "hidethis"]], null, null, null, i3.View_IonSpinner_0, i3.RenderType_IonSpinner)), i1.ɵdid(9, 49152, null, 0, i4.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "div", [["class", "DateDiv"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeliveryScheduleModalPage_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "h4", [["class", "TimeHead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select a slot"])), (_l()(), i1.ɵeld(15, 0, null, 0, 5, "div", [["class", "delivery-time"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { "deactive": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeliveryScheduleModalPage_2)), i1.ɵdid(20, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(21, 0, null, 0, 2, "ion-button", [["class", "DoneBTn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.done() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(22, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Done"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "none"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "primary"; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.scheduledDates; _ck(_v, 12, 0, currVal_2); var currVal_3 = "delivery-time"; var currVal_4 = _ck(_v, 18, 0, !_co.selectedDate); _ck(_v, 17, 0, currVal_3, currVal_4); var currVal_5 = _co.timeSchedules; _ck(_v, 20, 0, currVal_5); }, null); }
export function View_DeliveryScheduleModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delivery-schedule-modal", [], null, null, null, View_DeliveryScheduleModalPage_0, RenderType_DeliveryScheduleModalPage)), i1.ɵdid(1, 114688, null, 0, i5.DeliveryScheduleModalPage, [i4.Events, i6.Router, i6.ActivatedRoute, i4.AlertController, i4.LoadingController, i7.Storage, i4.ActionSheetController, i4.ModalController, i4.NavController, i8.LabelService, i9.ConfigService, i10.UserService, i11.SharedService, i4.ToastController, i12.MultiRegionService, i13.CartService, i14.OrderService, i15.AdminSettingsService, i16.DeliverySettingsService, i17.MultiCountriesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeliveryScheduleModalPageNgFactory = i1.ɵccf("app-delivery-schedule-modal", i5.DeliveryScheduleModalPage, View_DeliveryScheduleModalPage_Host_0, {}, {}, []);
export { DeliveryScheduleModalPageNgFactory as DeliveryScheduleModalPageNgFactory };
