import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductUtilsService {

  constructor(private router: Router) { }

  getBookingDuration(product) {
    let duration = null;
    if (product.scheduleData && product.scheduleData.duration) {
      let months = this.getDurationKeyString('month', product.scheduleData.duration.months);
      let days = this.getDurationKeyString('day', product.scheduleData.duration.days);
      let hours = this.getDurationKeyString('hr', product.scheduleData.duration.hours);
      let minutes = this.getDurationKeyString('min', product.scheduleData.duration.minutes);
      duration = `${months} ${days} ${hours} ${minutes}`;
    }
    return duration;
  }

  getDurationKeyString(key, value) {
    return value ? value > 1 ? `${value} ${key}s` : `${value} ${key}` : '';
  }

  goToProduct(product) {
    if (product.ptype === 'child') {
      this.router.navigate([`product/${product.parentSlug.name}/${product.slug.name}`]);
    } else {
      this.router.navigate([`product/${product.slug.name}`]);
    }
  }

  goToProductUsingRouterLink(product) {
    // console.log('product', product);
    return product.ptype === 'child' ?
      ['/product', product.parentSlug.name, product.slug.name] :
      ['/product', product.slug.name];
  }

  getProductQueryParams(data) {
    if (data && data.categorySlug && data.subCatSlug) {
      return { categorySlug: data.categorySlug, subCatSlug: data.subCatSlug };
    }
    else if (data && data.categorySlug && !data.subCatSlug) {
      return { categorySlug: data.categorySlug }
    }

    else if (data && data.brandSlug) {
      return { brandSlug: data.brandSlug }
    }

    else if (data && data.vendorSlug) {
      return { vendorSlug: data.vendorSlug }
    }
  }
}
