import { Component, OnInit, ViewChild } from '@angular/core';
import { Events, AlertController, LoadingController, ActionSheetController, IonContent, NavController,ModalController, ToastController  } from '@ionic/angular';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { UserService } from 'src/app/services/user/user.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import {SelectAddressPage} from '../select-address/select-address.page'
import { AutoConfirmPaymentPage } from '../auto-confirm-payment/auto-confirm-payment.page';
import { ImageModalPage } from '../image-modal/image-modal.page';
import { CouponsListPage } from '../pages/coupons-list/coupons-list.page';
import { MultiRegionService } from '../services/multi-region/multi-region.service';
import { encodeURL } from '../services/parseURL';
import { CartService } from '../services/cart/cart.service';
import { OrderService } from '../services/order/order.service';
import { AdminSettingsService } from '../services/admin-settings/admin-settings.service';
import { DeliverySettingsService } from '../services/delivery-settings/delivery-settings.service';
import { MultiCountriesService } from '../services/multi-countries/multi-countries.service';

@Component({
  selector: 'app-delivery-schedule-modal',
  templateUrl: './delivery-schedule-modal.page.html',
  styleUrls: ['./delivery-schedule-modal.page.scss'],
})
export class DeliveryScheduleModalPage implements OnInit {

  products: any = [];
  address: any;
  optionsforGallery: any;
  showCommentBoxAndImage: boolean = false;
  loading: any;
  loader: any;
  listOfCommentImages = {};
  placeholderMsgInCommentBox: string = '';
  defaultDeliveryAmt: number = 0;
  freeDeliveryAmt: number = 0;
  minOrderAmount: number = 0;
  maxOrderAmount: number;
  productsPrice: number = 0;
  totalAmountToPaid: number;
  showLoader: boolean = true;
  couponCode: string = '';
  couponDiscount: number = 0;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  defaultGst: number = 0;
  couponApplied: boolean = false;
  couponId: string = '';
  timeSchedules: any = [];
  scheduledDates: any = [];
  isDeliverySchedule: boolean;
  selectedDate: any;
  selectedTime: any;
  gstAmount: number = 0;
  autoConfirmOrder: boolean;
  maxDaysOfDelivery: number;
  isOrderDeliverable: string;
  showPriceInfoLoader: boolean = true;
  totalMrp: number = 0;
  discountOnMrp: number = 0;
  deliveryGstObj: any = {};
  paymentDetails: any = {};
  addressType: string = 'shipping';
  billingAddress: any;
  isShippingAndBillingSame: boolean;
  accountType: string = 'personal';
  customerGstNo: string = '';
  storeAddress: any = {};
  isStorePickup: boolean = false;
  storePickupCharges: number = 0;
  deliveryType: string = 'delivery';
  isGstApplicable: boolean = true;
  currencyCode: any;
  taxType: string;
  isDeliveryScheduleMandatory = false;
  minHrsReq = 0;
  allowSameDayDelivery = false;
  actualTime: any;
  minPincodeAmnt = 0;
  maxPincodeAmnt: number;
  SHARED_LABELS: any = {};
  ORDER_SUMMARY_LABELS: any = {};
  offerMsg = '';
  needToDisable = false;
  isNoSubscriptionOrder = false;
  manageSlots = [];
  user: any;
  membershipDiscount = 0;
  isOnlyMembership = false;
  uploadDocSettings: any = {
      isActive: false
  };
  uploadedDoc = {
      name: '',
      uploads: []
  }
  orderUploadedDocImgsLimit = 5;
  storeDelivery: any = {
      estimatedTime: '',
      isActive: true
  };
  isKmDeliveryExist: boolean = false;
  isWeightDeliveryExist: boolean = false;
  estimatedDeliveryTime: string = '';
  instantDelivery = {
      isActive: false,
      time: null,
      isAvailable: false
  };
  isCodAvailableForCoupon: boolean = true;
  regionNotDeliverable: boolean = false;
  issueInGettingLocation: boolean = false;
  multiRegion: boolean = false;
  regionType: string = 'pincodes';
  freeProductsLimit = [];
  freeProductsAdded = [];
  stickyHeight;
  stickyHeader;
  
  customDeliveryOptions = [];
  selectedCustomDeliveryType: string;
  extraChargeOnOrder = {
      name: '',
      charge: 0
  };

  customField = {
      active: false,
      name: '',
      value: ''
  };
  userId: any;
  totalAddonsPrice = 0;

  constructor(private events: Events,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private storage: Storage,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private navCtrl: NavController,
    private labelService: LabelService,
    private configService: ConfigService,
    private userService: UserService,
    private sharedService: SharedService,
    private toastController: ToastController,
    private regionService: MultiRegionService,
    private cartService: CartService,
    private orderService: OrderService,
    private adminSettingsService: AdminSettingsService,
    private deliverySettingsService: DeliverySettingsService,
    private multiCountriesService: MultiCountriesService,) {
      let cashfreeOrderId = this.activatedRoute.snapshot.queryParamMap.get('cf_id');
      if (cashfreeOrderId && cashfreeOrderId.length>0) {
          this.orderService.getOrderDetailsCashfree(cashfreeOrderId);
      }
     }

  ngOnInit() {
  }

  getStickyHeight(height){
    this.stickyHeight = height;
    console.log('sticky height', this.stickyHeight)
}
getScrollPos(pos: number) {
  if (pos > this.stickyHeight) {
      this.stickyHeader = true;
  } else {
    this.stickyHeader = false;
  }
}
async ionViewWillEnter() {
    this.initializeSubscriptions();
    this.varInit();

    this.products = await this.storage.get('productsInCart');
    this.products.forEach(product => {
        if (!product.hasOwnProperty('orderType') || (product.hasOwnProperty('orderType') && product.orderType !== 'subscription')) {
            this.isNoSubscriptionOrder = true;
        }
        if('extraCharges' in product && product.extraCharges.charge > 0) {
            const charge = product.extraCharges.chargeAllQty ? product.extraCharges.charge : product.extraCharges.charge / product.quantity;
            product.price += charge;
            if('mrpPrice' in product) {
                product.mrpPrice += charge;
            }
            product.extraCharges.charge = charge * product.quantity;
        }
        this.totalAddonsPrice += ('addOns' in product ? product.addOns.totalPrice : 0);
    });

    this.address = await this.storage.get('userDefaultAddress');
    const billingAddress = await this.storage.get('userBillingAddress');
    this.billingAddress = billingAddress || this.address;
    if(this.address && this.billingAddress) {
        if (this.isSame(this.address, this.billingAddress)) {
            this.isShippingAndBillingSame = true;
        } else {
            this.isShippingAndBillingSame = false;
        }
    }

    //
    /*let storeInfo: any = await this.adminSettingsService.getStoreInfoDataInSessionStorage();
    
    /*if ((Object.keys(storeInfo).length)) {
        console.log('storeInfo', storeInfo)
        this.showCommentBoxAndImage = storeInfo.allowComment || false;
        this.placeholderMsgInCommentBox = storeInfo.commentMsg;
        this.storeAddress = storeInfo.storeAddress || {};
        this.offerMsg = storeInfo.offerMsg || '';
        this.uploadDocSettings = storeInfo.allowImageUpload || this.uploadDocSettings;
        if(this.uploadDocSettings.isActive) {
            this.uploadedDoc['name'] = this.uploadDocSettings.name;
        }
    }*/
    const storeInfo = await this.storage.get('storeInfo');
    if (storeInfo && storeInfo !== undefined) {
        this.showCommentBoxAndImage = storeInfo.allowComment || false;
        this.placeholderMsgInCommentBox = storeInfo.commentMsg;
        this.storeAddress = storeInfo.storeAddress || {};
        this.offerMsg = storeInfo.offerMsg || '';
        this.uploadDocSettings = storeInfo.allowImageUpload || this.uploadDocSettings;
        if(this.uploadDocSettings.isActive) {
            this.uploadedDoc['name'] = this.uploadDocSettings.name;
        }
        this.extraChargeOnOrder.name = 'extraCharge' in storeInfo ? storeInfo.extraCharge.chargeName : '';
        this.customField = 'custom' in storeInfo && storeInfo.custom.active  ? storeInfo.custom : {active: false};
    }
    
    
    
    this.userId = await this.storage.get('uid');
    if(this.userId) {
        this.user = await this.userService.getUserDetails('return');
        this.customerGstNo = this.user.customerGstNo || '';
    }
    
    this.events.publish('delivery-settings:getDeliverySettingsData');
    this.events.publish('delivery-settings:getManageSlots');
    this.multiRegion = this.configService.environment.multiRegion;
    if(this.multiRegion) {
        this.events.publish('multi-region:getActiveStatus');
    }
}

urlPdtName(pname: string) {
    return encodeURL(pname);
}

varInit() {
    this.currencyCode = this.configService.environment.currencyCode;
    this.taxType = this.configService.environment.taxType;
    this.orderUploadedDocImgsLimit = this.configService.environment.orderUploadedDocImgsLimit;
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    this.ORDER_SUMMARY_LABELS = this.labelService.labels['ORDER_SUMMARY'];
}

ionViewWillLeave() {
    this.removeSubscriptions();
}

initializeSubscriptions() {
    this.events.subscribe('order:orderSuccessfullyPlaced', () => {
        if (this.sharedService.loading) {
            this.sharedService.loading.dismiss();
        }
        this.orderPlacedAlert();
    });

    this.events.subscribe('order:autoConfirmPlaceOrderSuccess', (orderObj) => {
        console.log("Came in delivery schedule modal");
        this.presentAutoConfirmPaymentPageModal(orderObj);
    });
    
    this.events.subscribe('delivery-settings:publishDeliverySettingsData', async (data) => {
        const customDeliveryOptions = await this.deliverySettingsService.getCustomDelivery();
        customDeliveryOptions.unshift({ id: 'standard', ...data });
        this.customDeliveryOptions = [];
        customDeliveryOptions.forEach(option => {
            if(this.isCustomDeliveryAvailable(option)) {
                this.customDeliveryOptions.push(option);
            }
        });
        if(this.customDeliveryOptions.length) {
            this.selectedCustomDeliveryType = this.customDeliveryOptions[0].id;
            this.setDeliveryObj(this.customDeliveryOptions[0]);
        } else {
            this.setDeliveryObj(data);
        }
        this.events.publish('admin-settings:getPaymentInfoData');
    });
    this.events.subscribe('admin-settings:publishPaymentInfoData', (data) => {
        if (!this.isEmptyObj(data)) {
            if (data.minOrderAmount !== '') {
                this.minOrderAmount = parseInt(data.minOrderAmount);
            }
            if (data.maxOrderAmount !== '') {
                this.maxOrderAmount = parseInt(data.maxOrderAmount);
            }
            this.autoConfirmOrder = data.autoConfirmOrder;
            this.isGstApplicable = typeof data.isGstApplicable !== undefined ? data.isGstApplicable : true;
            if(this.checkIsInternationalUser()) {
                this.isGstApplicable = false;
            }
            const additionalInfo = {
                totalAddonsPrice: this.totalAddonsPrice
            }
            this.events.publish('delivery-settings:getOrderPaymentDetails', this.address, this.products, this.isGstApplicable, this.customDeliveryOptions[0] || null, additionalInfo);
            this.showLoader = false;
            this.showPriceInfoLoader = true;
        }
    });
    this.events.subscribe('delivery-settings:publishOrderPaymentDetails', async (response) => {
        this.paymentDetails = response;
        if (!this.isKmDeliveryExist && !this.isWeightDeliveryExist) {
            this.getExtraDeliveryFields();
        }
        this.setDefaultSelectionofDelivery();
        this.initPaymentInfo();
        await this.getFreeProductLimits();
        this.checkFreeProductStatus();
        this.showPriceInfoLoader = false;
        if (this.loader) {
            this.loader.dismiss();
        }
    });
    this.events.subscribe('coupon-codes:couponCodeNotApplied', (msg) => {
        this.couponCode = '';
        this.loader.dismiss();
        this.presentAlert(msg);
    });
    this.events.subscribe('coupon-codes:couponCodeApplied', (response) => {
        if (response.details.totalCouponDiscount === 0) {
            this.loader.dismiss();
            this.presentAlert(`${this.ORDER_SUMMARY_LABELS['coupon_not_applicable']}`);
        } else {
            let coupon = response.data;
            this.couponApplied = true;
            this.couponId = coupon.couponId;
            this.isCodAvailableForCoupon = 'codAvailable' in coupon ? coupon.codAvailable : true;
            this.gstAmount = response.details.totalGst;
            this.products = response.details.products;
            this.totalAmountToPaid = response.details.totalAmountToPaid;
            this.couponDiscount = response.details.totalCouponDiscount;
            this.totalMrp = 'totalMrp' in response.details ? response.details.totalMrp : this.totalMrp;
            this.discountOnMrp = 'discountOnMrp' in response.details ? response.details.discountOnMrp : this.discountOnMrp;
            let price = 0;
            let totalGst = 0;
            this.products.map((p) => {
                price += p.price * p.quantity;
                if (p.gstObj) {
                    totalGst += p.gstObj.total;
                }
            });
            if (price >= this.freeDeliveryAmt) {
                this.deliveryFree();
            }
            if (this.deliveryType === 'pickup' && this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
                this.setStorePickupPayment();
            }
            this.checkUserMembership();
            this.checkFreeProductStatus();
            this.loader.dismiss();
        }
    });
    this.events.subscribe('delivery-settings:publishManageSlots', (slots) => {
        this.manageSlots = slots;
    });

    this.events.subscribe('multi-region:publishActiveStatus', (data) => {
        if(data) {
            this.multiRegion = data.active;
            this.regionType = data.regionType || 'pincodes';
            if(this.multiRegion && this.regionType === 'area') {
                this.getNearestStoreRegion();
            }
        }
    });
}
isEmptyObj(object) {
    for (var key in object) {
        if (object.hasOwnProperty(key))
            return false;
    }
    return true;
}

async presentAutoConfirmPaymentPageModal(orderObj) {
    this.events.unsubscribe('admin-settings:publishPaymentInfoData');
    const {isCodAvailableForCoupon, ...order} = orderObj;
    const modal = await this.modalController.create({
      component: AutoConfirmPaymentPage,
      cssClass: 'custom-modal',
      componentProps: {
        orderData: order,
        isCodAvailableForCoupon
      }
    });
    return await modal.present();
  }

getDeliveryDates(days, lastDeliveryTime) {
  this.showLoader = false;
    this.scheduledDates = [];
    for (let index = 0; index < this.maxDaysOfDelivery; index++) {
        let dayToCheck = moment().add(index + 1, 'days');
        if (days.includes(dayToCheck.format('dddd'))) {
            this.scheduledDates.push(dayToCheck.toDate());
        }
    }
    if (this.allowSameDayDelivery) {
        this.appendSameDateToDeliveryDates(lastDeliveryTime);
    }
    this.minHrsReqCheck();
}

appendSameDateToDeliveryDates(lastTime) {
    let now: any = moment().format('HH:mm');
    let lastDeliveryTime: any = moment(lastTime, ['hh:mm A']).format('HH:mm');
    if (now < lastDeliveryTime) {
        this.scheduledDates.unshift(new Date());
    }
}

minHrsReqCheck() {
    const now: any = parseInt(moment().format('HH'));
    const t1 = now + this.minHrsReq;
    if (t1 > 24) {
        let disabledDays = Math.floor(t1 / 24);
        this.actualTime = t1 % 24;
        let actualDays = [];
        disabledDays = this.allowSameDayDelivery ? disabledDays : disabledDays - 1;
        disabledDays = disabledDays < 0 ? 0 : disabledDays;
        for (let index = disabledDays; index < this.scheduledDates.length; index++) {
            actualDays.push(this.scheduledDates[index]);
        }
        this.scheduledDates = actualDays;
        this.needToDisable = true;
    } else {
        this.actualTime = t1;
        if (this.allowSameDayDelivery) {
            this.needToDisable = true;
        }
    }
}

selectDate(e) {
    this.selectedDate = e.target.value;
    this.selectedTime = null;
}
selectTime(e) {
    this.selectedTime = e.target.value;
}
disableTime(time) {
    if (this.selectedDate) {
        if (this.needToDisable) {
            const d1 = this.formatDate(this.selectedDate);
            const d2 = this.formatDate(new Date(this.scheduledDates[0]));
            if (d1 === d2) {
                const startTime = moment(time.start, ['hh:mm A']).format('HH');
                if (this.actualTime > parseInt(startTime)) {
                    return true;
                }
            }
        }
        if (this.manageSlots.length > 0) {
            let needToDisable = false;
            this.manageSlots.forEach(manageSlot => {
                if (this.selectedDate.toDateString() === manageSlot.id) {
                    const slots = manageSlot.slots;
                    slots.forEach(slot => {
                        if ((time.start === slot.start) && (time.end === slot.end)) {
                            if (!slot.active) {
                                needToDisable = true;
                            }
                            if (slot.orderLimit <= slot.orderCreated) {
                                needToDisable = true;
                            }
                        }
                    });
                }
            });
            if (needToDisable) {
                return true;
            }
        }
    }
}

formatDate(d) {
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    return day + '/' + month + '/' + year;
}

async onClickPlaceOrder() {
    if(!this.userId) {
        this.sharedService.openLoginModal();
        return;
    }
    if(!this.address) {
        this.onClickChangeOrAddAddress('shipping');
        return;
    }
    this.productsPrice = 0;
    this.products.map((p) => {
        this.productsPrice += ((('pack' in p) && (p.pack.variantType === 'pieces')) ? p.pack.price : p.price) * p.quantity;
    });

    let uploadDocMandatory = false;
    if(this.uploadDocSettings.isActive && this.uploadDocSettings.isMandatory && !this.uploadedDoc.uploads.length) {
        uploadDocMandatory = true;
    }

    if (this.isOrderDeliverable === 'not_deliverable') {
        this.presentAlert(this.getNotDeliverableError());
    } 
    
    else if (this.productsPrice < this.minOrderAmount) {
        this.presentAlert(`${this.ORDER_SUMMARY_LABELS['min_amount_for_placing_order']} ${this.currencyCode} ${this.minOrderAmount}`);
    } 
    
    else if (this.maxOrderAmount && (this.productsPrice > this.maxOrderAmount)) {
        this.presentAlert(`${this.ORDER_SUMMARY_LABELS['max_amount_for_placing_order']} ${this.currencyCode} ${this.maxOrderAmount}`);
    } 
    
    else if (this.deliveryType !== 'pickup' && this.isDeliveryScheduleMandatory && (!this.selectedDate || !this.selectedTime) && this.isNoSubscriptionOrder && !this.isOnlyMembership) {
        this.presentAlert(`${this.ORDER_SUMMARY_LABELS['select_delivery_date_time']}`);
    } 
    
    else if (this.productsPrice < this.minPincodeAmnt) {
        this.presentAlert(`${this.ORDER_SUMMARY_LABELS['min_amount_for_placing_order_on_pincode']} ${this.currencyCode} ${this.minPincodeAmnt}`);
    } else if (uploadDocMandatory) {
        this.presentAlert(`${this.ORDER_SUMMARY_LABELS['please_upload_doc']} ${this.uploadDocSettings.name}`);
    } else if (this.regionNotDeliverable) {
        this.presentAlert(this.ORDER_SUMMARY_LABELS['not_delivering_in_your_location_msg']);
    } else if (this.issueInGettingLocation) {
        this.presentAlert(this.ORDER_SUMMARY_LABELS['issue_in_getting_nearest_store_location']);
    } else {

        if ((this.totalAmountToPaid - this.defaultDeliveryAmt) >= this.freeDeliveryAmt) {
            this.defaultDeliveryAmt = 0;
        }
        let paymentData = {
            productsPrice: this.productsPrice,
            delivery: this.defaultDeliveryAmt,
            couponDiscount: this.couponDiscount,
            defaultGst: this.gstAmount,
            totalAmountToPaid: this.totalAmountToPaid,
            couponId: this.couponId,
            couponName: this.couponCode,
            scheduledDate: this.selectedDate ? this.selectedDate : '',
            scheduledTime: this.selectedTime ? this.selectedTime : '',
            totalMrp: this.totalMrp,
            discountOnMrp: this.discountOnMrp,
            deliveryGstObj: this.deliveryGstObj,
            customerGstNo: this.customerGstNo,
            billingAddress: this.billingAddress,
            membershipDiscount: this.membershipDiscount,
            storePickupObj: {},
            uploadedDoc: this.uploadedDoc,
            estimatedDeliveryTime: '',
            instantDelivery: this.deliveryType === 'instant' ? {selected: true, time: this.instantDelivery.time} : {selected: false},
            metaData: { source: 'browser', inventoryManaged: this.autoConfirmOrder ? true : false },
            autoConfirmOrder: this.autoConfirmOrder,
            freeProductsAdded: this.freeProductsAdded,
            extraChargeOnOrder: this.extraChargeOnOrder,
            custom: this.customField,
            totalAddonsPrice: this.totalAddonsPrice
        };

        if(this.deliveryType === 'default_delivery' && this.storeDelivery.estimatedTime) {
            paymentData.estimatedDeliveryTime = this.storeDelivery.estimatedTime;
        }

        if(this.deliveryType === 'estimated') {
            paymentData.estimatedDeliveryTime = this.estimatedDeliveryTime;
        }

        if (this.deliveryType === 'pickup' && this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
            paymentData['storePickupObj'] = {
                storeAddress: this.storeAddress,
                charges: this.storePickupCharges
            };
        }

        if (!this.autoConfirmOrder) {
            await this.sharedService.presentLoading(20000);
            const isProductsUpdated = (await this.cartService.compareCartWithUpdatedCart(this.products, 'order-summary')).cartUpdated;
            if(isProductsUpdated) {
                this.sharedService.loading.dismiss();
                await this.presentAlert(this.SHARED_LABELS['cart_updated_alert']);
                this.router.navigate(['user-cart']);
            } else {
                this.events.publish('order:placeOrder', this.products, this.listOfCommentImages, this.address, paymentData);
            }
        } else {
            this.events.publish('order:autoConfirmPlaceOrder', this.products, this.listOfCommentImages, this.address, paymentData, this.isCodAvailableForCoupon);
        }
    }
}
async onClickChangeOrAddAddress(type: string) {
    const modal = await this.modalController.create({
    component: SelectAddressPage,
    cssClass: 'custom-modal',
    componentProps: { type: type }
    });

    modal.onDidDismiss().then(async () => {
        this.address = await this.storage.get('userDefaultAddress');
        this.billingAddress = await this.storage.get('userBillingAddress');

        this.events.publish('delivery-settings:getDeliverySettingsData');
        this.events.publish('delivery-settings:getManageSlots');
        if(this.multiRegion && this.regionType === 'area') {
            this.getNearestStoreRegion();
        }
    });

    await modal.present();

  }



async applyCouponCode() {
    if(!this.userId) {
        this.sharedService.openLoginModal();
        return;
    }
    this.paymentDetails.products = this.products.filter(p => p.orderType !== 'free');
    await this.presentLoading(`${this.ORDER_SUMMARY_LABELS['verifying_coupon_code']}`);
    const data = {
        code: this.couponCode,
        paymentDetails: this.paymentDetails,
        isGstApplicable: this.isGstApplicable
    };
    this.events.publish('coupon-codes:verifyCouponCode', data);
}

async removeCouponCode() {
    this.couponApplied = false;
    this.couponDiscount = 0;
    this.couponCode = '';
    this.couponId = '';
    this.isCodAvailableForCoupon = true;
    this.initPaymentInfo();
    this.checkFreeProductStatus();
}

async openCouponsModal() {
    if(this.couponApplied) {
        return;
    } else {
        const modal = await this.modalController.create({
            component: CouponsListPage,
            cssClass: 'coupons-list-modal modal-bg'
        });
        modal.onDidDismiss().then(async (res) => {
            if (res && res.data) {
                this.couponCode = res.data.couponName;
                this.applyCouponCode();
            }
        });
        await modal.present();
    }
}


scrollToBottom() {
    this.content.scrollToBottom(500);
}
async orderPlacedAlert() {
    const alert = await this.alertController.create({
        header: this.ORDER_SUMMARY_LABELS['order_placed_alert_header'],
        message: this.ORDER_SUMMARY_LABELS['order_placed_alert_message'],
        backdropDismiss: false,
        buttons: [{
            text: 'OK',
            handler: () => {
                this.navCtrl.navigateRoot(['order-successful']);
                this.modalController.dismiss()
            }
        }]
    });
    await alert.present();
}
async presentAlert(msg: string) {
    const alert = await this.alertController.create({
        message: msg,
        buttons:this.SHARED_LABELS['ok']
    });

    await alert.present();
}
async presentLoading(msg: string) {
    this.loader = await this.loadingController.create({
        message: msg,
        duration: 30000
    });
    await this.loader.present();
}



textUppercase() {
    this.couponCode = this.couponCode.toUpperCase();
    if (this.couponCode.includes(' ')) {
        this.couponCode = this.couponCode.replace(/\s/g, '');
    }
}

changeAddressType(e) {
    this.addressType = e.target.value;
    if (this.addressType === 'billing') {
        if (this.isSame(this.address, this.billingAddress)) {
            this.isShippingAndBillingSame = true;
        } else {
            this.isShippingAndBillingSame = false;
        }
    }
}

isSame(a, b) {
    if (a.id === b.id) {
        return true;
    } else {
        return false;
    }
}

changePersonalType(e) {
    this.accountType = e.target.value;
    if (this.accountType === 'personal') {
        this.customerGstNo = '';
    }
}

closeModal() {
  this.modalController.dismiss();
}

changeDeliveryType(e) {
    this.couponApplied = false;
    this.couponDiscount = 0;
    this.deliveryType = e.target.value;

    if (this.isStorePickup && this.storeAddress.hasOwnProperty('address')) {
        if (this.deliveryType === 'pickup') {
            this.setStorePickupPayment();
            this.checkUserMembership();
        } else {
            this.selectedDate = null;
            this.selectedTime = null;
            this.initPaymentInfo();
        }
    }

}

async checkUserMembership() {
    let membership = { isMember: false, settings: {} };
    let membershipInCart = false;
    if (this.user && this.user.hasOwnProperty('membership') && this.user.membership.isMember) {
        membership.isMember = true;
        membership['settings'] = this.user.membership.planConfig;
    } else {
        this.products.forEach(product => {
            if (product.hasOwnProperty('orderType') && product.orderType === 'membership') {
                membership.isMember = true;
                membership['settings'] = product.membershipSettings;
                membershipInCart = true;
            }
        });
        if (membership.isMember && this.products.length === 1) {
            this.isOnlyMembership = true;
            this.freeDeliveryAmt = 0;
            this.deliveryFree();
        }
    }
    if (membership.isMember && !this.isOnlyMembership) {
        const settings: any = membership.settings;
        console.log('settings', settings);
        const totalProductPrice = this.totalMrp - this.discountOnMrp - this.couponDiscount;
        if (settings.isDeliveryFree && (totalProductPrice >= settings.deliveryFreeAmount)) {
            this.deliveryFree();
            this.freeDeliveryAmt = settings.deliveryFreeAmount;
        }
        if (settings.discount > 0) {
            let memberDiscount = 0;
            if (membershipInCart) {
                memberDiscount = (totalProductPrice - settings.plan.discountedPrice) * (settings.discount / 100);
            } else {
                memberDiscount = totalProductPrice * (settings.discount / 100);
            }
            memberDiscount = parseFloat((memberDiscount > settings.maxDiscount ? settings.maxDiscount : memberDiscount).toFixed(2));
            this.products.map(pdt => {
                const discountOnPdt = memberDiscount * (pdt.price / (this.totalMrp - this.discountOnMrp));
                pdt.membershipDiscount = (discountOnPdt > pdt.price) ? pdt.price * pdt.quantity : discountOnPdt * pdt.quantity;
            });
            this.membershipDiscount = memberDiscount;
            this.totalAmountToPaid = (this.totalAmountToPaid - memberDiscount) < 0 ? 0 : this.totalAmountToPaid - memberDiscount;
        }
    }
    console.log('this.membershipDiscount', this.membershipDiscount);
}

initPaymentInfo() {
    this.products = this.paymentDetails.products;
    this.defaultDeliveryAmt = this.paymentDetails.delivery.deliveryCost;
    this.isOrderDeliverable = this.paymentDetails.delivery.status;
    this.totalMrp = this.paymentDetails.totalMrp;
    this.discountOnMrp = this.paymentDetails.discountOnMrp;
    this.gstAmount = this.paymentDetails.totalGst;
    this.deliveryGstObj = this.paymentDetails.deliveryGstObj;
    this.totalAmountToPaid = this.paymentDetails.totalPayable;
    this.extraChargeOnOrder.charge = 'extraCharge' in this.paymentDetails ? this.paymentDetails.extraCharge : 0;
    if(this.deliveryType === 'pickup') {
        this.checkIfOnlyStorePickup();
    }
    this.checkUserMembership();

    if ('freeDeliveryAmount' in this.paymentDetails.delivery && this.paymentDetails.delivery.freeDeliveryAmount) {
        this.freeDeliveryAmt = this.paymentDetails.delivery.freeDeliveryAmount;
    }
}

deliveryFree() {
    this.defaultDeliveryAmt = 0;
    this.deliveryGstObj = {
        value: 0,
        total: 0
    };
    this.totalAmountToPaid -= this.paymentDetails.delivery.deliveryCost;
}

checkIfOnlyStorePickup() {
    if ((!this.isDeliverySchedule || !this.scheduledDates.length) && (this.isStorePickup && this.storeAddress.hasOwnProperty('address'))) {
        this.setStorePickupPayment();
    }
}

setStorePickupPayment() {
    this.totalAmountToPaid = parseFloat(((this.paymentDetails.totalPayable - this.paymentDetails.delivery.deliveryCost - this.couponDiscount) + this.storePickupCharges).toFixed(2));
    this.gstAmount += (this.storePickupCharges - (this.storePickupCharges / (1 + ((this.deliveryGstObj.value) / 100)))) - this.paymentDetails.deliveryGstObj.total;
    this.deliveryGstObj.total = (this.storePickupCharges - (this.storePickupCharges / (1 + ((this.deliveryGstObj.value) / 100))));
}


async uploadDocImage(files: FileList) {
    for (let i = 0; i < files.length; i++) {
        if(this.uploadedDoc.uploads.length < this.orderUploadedDocImgsLimit) {
            console.log('in if....');
            const base64Image = await this.readBase64(files.item(i));
            this.uploadedDoc.uploads.push({url: base64Image});
        } else {
            console.log('in else....');
            break;
        }
    }
  }

  readBase64(file) {
      return new Promise(async (resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = (event:any) => { // called once readAsDataURL is completed
          resolve(event.target.result);
        }
      });
  }

removeUploadedDocImg(index: number) {
    this.uploadedDoc.uploads.splice(index, 1);
}

imgZoom(img: any) {
    this.modalController.create({
      component: ImageModalPage,
      cssClass:'photo-modal-class',
      componentProps: {
        imgs: [{url: img}],
        index: 0
      }
    }).then(modal => modal.present());
  }


isDeliverySchedulesAvailable() {
    return this.isDeliverySchedule && this.scheduledDates.length > 0 ? true : false;
}

isStorePickupAvailable() {
    return this.isStorePickup && this.storeAddress.hasOwnProperty('address') ? true : false;
}

isDefaultDeliveryAvailable() {
    return this.storeDelivery.isActive ? true : false;
}

getExtraDeliveryFields() {
    const data = this.paymentDetails.delivery;
    if('minAmount' in data) {
        this.minPincodeAmnt = data.minAmount;
    }
    if('estimatedDeliveryTime' in data) {
        console.log('estimatedDeliveryTime', data.estimatedDeliveryTime);
        if(data.estimatedDeliveryTime) {
            let totalEstimatedHrs = this.getEstimatedTimeInHrs(data.estimatedDeliveryTime);
            const estimatedDate = moment().add(totalEstimatedHrs, 'hours').format('dddd, MMMM DD');
            this.estimatedDeliveryTime = estimatedDate;

            if(this.instantDelivery.isActive) {
                this.checkInstantDelivery(totalEstimatedHrs);
            }
        }
    }
    if('freeDeliveryAmount' in data) {
        this.freeDeliveryAmt = data.freeDeliveryAmount
    }
}

getEstimatedTimeInHrs(time) {
    const splitTime = time.split(':');
    const days = +splitTime[0];
    const hrs = +splitTime[1];
    const mins = +splitTime[2];
    let totalHrs = (days * 24) + hrs + (mins / 60);
    return totalHrs;
}

showDeliverySection() {
    if ((this.isDeliverySchedulesAvailable() || this.isStorePickupAvailable() || this.isInstantDeliveryAvailable() || this.isEstimatedDeliveryAvailable() || this.isDefaultDeliveryAvailable())
    && this.isNoSubscriptionOrder && !this.isOnlyMembership) {
        return true;
    } else {
        return false;
    }
}

isInstantDeliveryAvailable() {
    return this.instantDelivery.isAvailable;
}

isEstimatedDeliveryAvailable() {
    return this.estimatedDeliveryTime && this.estimatedDeliveryTime !== '' ? true : false;
}

setDefaultSelectionofDelivery() {
    if(this.isDeliverySchedulesAvailable()) {
        this.deliveryType = 'delivery';
    } else if(this.isInstantDeliveryAvailable()) {
        this.deliveryType = 'instant';
    } else if(!this.isDeliverySchedulesAvailable() && this.isEstimatedDeliveryAvailable()) {
        console.log('isEstimatedDeliveryAvailable');
        this.deliveryType = 'estimated';
    } else if(!this.isDeliverySchedulesAvailable() && !this.isEstimatedDeliveryAvailable() && this.isDefaultDeliveryAvailable()) {
        this.deliveryType = 'default_delivery';
    } else if(this.isStorePickupAvailable()) {
        console.log('isStorePickupAvailable');
        this.deliveryType = 'pickup';
    }
}

checkInstantDelivery(estimatedTime: number) {
    const todayAvailable = moment().isSame(moment(this.scheduledDates[0]));
    if((this.instantDelivery.time >= estimatedTime) && todayAvailable) {
        this.instantDelivery['isAvailable'] = true;
        this.instantDelivery.time = this.instantDelivery.time <= 1 ? `${this.instantDelivery.time * 60} mins` : `${this.instantDelivery.time} hrs`; 
    }
}

isMembershipInProducts(orderType) {
    return orderType && orderType === 'membership' ? true : false;
}

async uploadCommentImgs(files: FileList, pid) {

    let imgs = this.listOfCommentImages[pid] || [];
    for (let i = 0; i < files.length; i++) {
        const base64Image = await this.readBase64(files.item(i));
        if(imgs.length !== 0 ) {
            imgs.push(base64Image);
        } else {
            imgs = [base64Image];
        }
        this.listOfCommentImages[pid] = imgs;
    }
}

removeCommentImage(imgIndex, pid) {
    this.listOfCommentImages[pid].splice(imgIndex, 1);
    console.log('listOfCommentImages', this.listOfCommentImages);
}

async getNearestStoreRegion() {
    await this.sharedService.presentLoading(20000, this.ORDER_SUMMARY_LABELS['getting_nearest_store_location']);
    const res: any = await this.getRegion();
    if (res.status === 'success') {
        if (res.deliverable) {
            const region = {
                id: res.region.id,
                name: res.region.name,
                pincode: null,
                vendorId: res.region.vendorId || null
            }
            this.storage.set('region', region);
            this.sharedService.loading.dismiss();
        } else {
            this.sharedService.loading.dismiss();
            this.storage.set('region', null);
            this.regionNotDeliverable = true;
            this.presentAlert(this.ORDER_SUMMARY_LABELS['not_delivering_in_your_location_msg']);
        }
    } else {
        this.sharedService.loading.dismiss();
        this.issueInGettingLocation = true;
        this.presentAlert(this.ORDER_SUMMARY_LABELS['issue_in_getting_nearest_store_location'])
    }
}

getRegion() {
    return new Promise(async (resolve, reject) => {
        const nearestRegion: any = await this.regionService.getNearestStoreRegion(this.address);
        resolve(nearestRegion);
    });

}

async getFreeProductLimits() {
    const limits: any = await this.cartService.getFreeProductLimits();
    if(limits.length) {
        this.freeProductsLimit = limits;
    }
}

checkFreeProductStatus() {
    this.freeProductsAdded = [];
    const products = this.products.filter(p => p.orderType !== 'free');
    if(this.freeProductsLimit.length) {
        let totalPrice = this.getTotalPriceWithoutDelivery();
        let limitIndex = -1;
        for (let index = 0; index < this.freeProductsLimit.length; index++) {
            if(totalPrice >= this.freeProductsLimit[index].orderAmount) {
                limitIndex = index;
                break;
            }
        }
        if(limitIndex !== -1) {
            this.freeProductsAdded.push(this.freeProductsLimit[limitIndex].id);
            this.freeProductsLimit[limitIndex].freeProduct['gstObj'] = {
                value: 0,
                total: 0,
                cgst: 0,
                sgst: 0,
                igst: 0
            }
            this.freeProductsLimit[limitIndex].freeProduct['orderType'] = 'free';
            products.push(this.freeProductsLimit[limitIndex].freeProduct);
            this.products = JSON.parse(JSON.stringify(products));
        }
    }
}

moreAmountForFreeProducts() {
    return this.freeProductsLimit[this.freeProductsLimit.length - 1].orderAmount - this.getTotalPriceWithoutDelivery();
}

getTotalPriceWithoutDelivery() {
    return this.totalMrp - this.discountOnMrp - this.couponDiscount - this.membershipDiscount;
}

setDeliveryObj(data) {
    if (!this.isEmptyObj(data)) {
        if (data.freeDeliveryAmt !== '') {
            this.freeDeliveryAmt = parseInt(data.freeDeliveryAmt);
            console.log('freeDeliveryAmt', this.freeDeliveryAmt);
        }

        this.storeDelivery = 'isStoreDelivery' in data ? data.isStoreDelivery : this.storeDelivery;
        if (this.storeDelivery.isActive && this.storeDelivery.estimatedTime) {
            const hrs = this.getEstimatedTimeInHrs(this.storeDelivery.estimatedTime);
            this.storeDelivery.estimatedTime = moment().add(hrs, 'hours').format('dddd, MMMM DD');
        }

        this.isDeliverySchedule = data.isDeliverySchedule && this.storeDelivery.isActive ? true : false;
        this.isStorePickup = data.isStorePickup;
        if (data.storePickupCharges) {
            this.storePickupCharges = parseInt(data.storePickupCharges);
        }

        this.maxDaysOfDelivery = data.maxDaysOfDelivery;
        if (this.isDeliverySchedule) {
            if (data.allowSameDayDelivery) {
                this.allowSameDayDelivery = data.allowSameDayDelivery;
            }
            if (data.minHrs) {
                this.minHrsReq = data.minHrs;
            }
            if (data.deliveryDays.length > 0 && data.timeSchedules.length > 0) {
                this.getDeliveryDates(data.deliveryDays, data.lastDeliveryTime);
                this.timeSchedules = data.timeSchedules;
            }
        }

        this.isKmDeliveryExist = data.isKmBasedDelivery && this.storeAddress.lat && this.address.lat;
        this.isWeightDeliveryExist = data.hasOwnProperty('deliveryByWeight') && data.deliveryByWeight.active ? true : false;

        this.instantDelivery = 'instantDelivery' in data ? { ...this.instantDelivery, ...data.instantDelivery } : this.instantDelivery;
        
        if (typeof data.isDeliveryScheduleMandatory !== 'undefined') {
            this.isDeliveryScheduleMandatory = data.isDeliveryScheduleMandatory;
        }
    }
}

changeCustomDeliveryOption(event) {
    const customDeliverySettings = this.customDeliveryOptions.find(c => c.id === event.target.value);
    console.log('customDeliverySettings', customDeliverySettings);
    if(customDeliverySettings && Object.keys(customDeliverySettings).length) {
        this.setDeliveryObj(customDeliverySettings);
        this.events.publish('delivery-settings:getOrderPaymentDetails', this.address, this.products, this.isGstApplicable, customDeliverySettings);
        this.couponApplied = false;
        this.couponDiscount = 0;
        this.couponCode = '';
        this.couponId = '';
        this.showPriceInfoLoader = true;
        this.selectedCustomDeliveryType = customDeliverySettings.id;
    }
}

getNotDeliverableError() {
    if(this.selectedCustomDeliveryType) {
        return `${this.selectedCustomDeliveryType} delivery type not available. Please select another option.`;
    } else {
        return `${this.ORDER_SUMMARY_LABELS['order_not_deliverable']}`;
    }
}

isCustomDeliveryAvailable(data: any) {
    if('availableLocations' in data && this.address) {
        if(data.availableLocations.states.some(s => s.stateCode === this.address.stateCode)) {
            return true;
        }
        if(data.availableLocations.pincodes.some(p => p === this.address.pincode)) {
            return true;
        }
        return false;
    } else {
        return true;
    }
}


checkIsInternationalUser() {
    const adminCountry = this.multiCountriesService.multiCountriesSettings.settings.defaultCountry;
    if(this.address && adminCountry.countryName && adminCountry.countryName.toLowerCase() === 'india' && this.address.country && this.address.country.toLowerCase() !== 'india') {
        return true;
    } else {
        return false;
    }
}

isAddonAvailable(product) {
    return product.addOns && product.addOns.options && Object.keys(product.addOns.options).length;
}

removeSubscriptions() {
    this.events.unsubscribe('order:orderSuccessfullyPlaced');
    this.events.unsubscribe('delivery-settings:publishDeliverySettingsData');
    this.events.unsubscribe('admin-settings:publishPaymentInfoData');
    this.events.unsubscribe('delivery-settings:publishOrderPaymentDetails');
    this.events.unsubscribe('coupon-codes:couponCodeNotApplied');
    this.events.unsubscribe('coupon-codes:couponCodeApplied');
    this.events.unsubscribe('delivery-settings:publishManageSlots');
    this.events.unsubscribe('order:autoConfirmPlaceOrderSuccess');
}
dateSelect(date){
  this.selectedDate = date;
  this.selectedTime = null;
  console.log('selectedDate', this.selectedDate)
}

timeSelect(time){
  this.selectedTime =  time;
  console.log('selectedDate', this.selectedTime)
}



done() {
  this.modalController.dismiss({selectedDate: this.selectedDate, selectedTime: this.selectedTime })
}
}
