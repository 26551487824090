import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { AlertController, LoadingController, Events, Platform, ModalController, NavParams } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { StatesModalPage } from '../states-modal/states-modal.page';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from '../services/config/config.service';
import { MapPage } from './map/map.page';
import { MultiCountriesService } from '../services/multi-countries/multi-countries.service';
// import {    } from '@angular/core';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
export class NewAddressPage {
    constructor(events, router, alertController, loadingController, route, userService, platform, navParams, configService, multiCountriesService, modalController, deliveryService, labelService) {
        this.events = events;
        this.router = router;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.route = route;
        this.userService = userService;
        this.platform = platform;
        this.navParams = navParams;
        this.configService = configService;
        this.multiCountriesService = multiCountriesService;
        this.modalController = modalController;
        this.deliveryService = deliveryService;
        this.labelService = labelService;
        this.addressObj = {
            name: '',
            address: '',
            city: '',
            state: '',
            stateCode: '',
            pincode: '',
            phoneNo: '',
            createdAt: null,
            defaultAddress: true,
            lat: null,
            lng: null,
            country: '',
            flatNo: '',
            floor: '',
            landmark: ''
        };
        this.routeFromCheckoutPage = false;
        this.routeFromSelectAddress = false;
        this.routeFromMyAddresses = false;
        // By default google headquarters
        this.lat = 37.419857;
        this.lng = -122.078827;
        this.states = [];
        this.addressType = 'shipping';
        this.SHARED_LABELS = {};
        this.NEW_ADDRESS_LABELS = {};
        this.countries = [];
        this.pageType = 'page';
        if (navParams.get('addressData')) {
            this.editAddressData = navParams.get('addressData');
        }
        if (navParams.get('addressLength')) {
            this.addressLength = navParams.get('addressLength');
        }
        if (navParams.get('routeFromCheckoutPage')) {
            this.routeFromCheckoutPage = navParams.get('routeFromCheckoutPage');
        }
        if (navParams.get('routeFromSelectAddress')) {
            this.routeFromSelectAddress = navParams.get('routeFromSelectAddress');
        }
        if (navParams.get('routeFromMyAddresses')) {
            this.routeFromSelectAddress = navParams.get('routeFromMyAddresses');
        }
        if (navParams.get('type')) {
            this.addressType = navParams.get('type');
        }
    }
    ngOnInit() {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('position:', position);
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            setTimeout(() => {
                this.loadMap();
            }, 500);
        }, (error) => {
            setTimeout(() => {
                this.loadMap();
            }, 500);
            console.log('error:', error);
        });
    }
    ionViewDidEnter() {
        this.initializeSubscriptions();
        this.addressObj.phoneNo = this.userService.getPhoneNo();
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.NEW_ADDRESS_LABELS = this.labelService.labels['NEW_ADDRESS'];
        this.countries = this.multiCountriesService.multiCountriesSettings.countries;
    }
    ionViewDidLeave() {
        this.removeSubscriptions();
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
                duration: 10000
            });
            yield this.loading.present();
        });
    }
    loadMap() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('lat:', this.lat, 'lng:', this.lng);
            let latLng = yield new google.maps.LatLng(this.lat, this.lng);
            console.log('latLng obj:', latLng);
            let mapOptions = {
                center: latLng,
                zoom: 15,
                scrollwheel: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
            let marker = new google.maps.Marker({
                map: this.map,
                animation: google.maps.Animation.DROP,
                draggable: true,
                position: this.map.getCenter()
            });
            this.marker = marker;
            let content = `<p><b>Order will be delivered hare</b></p><p>Place the pin accurately on the map</p>`;
            let infoWindow = new google.maps.InfoWindow({
                content: content
            });
            infoWindow.open(this.map, marker);
            new google.maps.event.addListener(this.marker, 'dragend', function (event) {
            });
        });
    }
    saveAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.presentLoading();
            let latLngObj = {
                lat: this.marker.getPosition().lat(),
                lng: this.marker.getPosition().lng()
            };
            const address = yield this.deliveryService.getAddressFromLatLng(latLngObj);
            const formatted_address = address.results[0].formatted_address;
            this.loading.dismiss();
            this.modalController.dismiss(formatted_address);
        });
    }
    modalDismiss() {
        this.modalController.dismiss();
    }
    initializeSubscriptions() {
        this.events.subscribe('user:newAddressSaved', () => {
            this.loading.dismiss();
            if (this.routeFromCheckoutPage === true || this.routeFromSelectAddress === true) {
                this.modalDismiss();
                this.router.navigate(['order-summary']);
            }
            else {
                if (this.pageType === 'modal') {
                    this.modalController.dismiss({ close: false });
                }
                else {
                    this.presentAlert(`${this.NEW_ADDRESS_LABELS['address_saved_successfully']}`, true);
                }
            }
        });
        this.events.subscribe('user:addressEditSuccess', () => {
            this.loading.dismiss();
            this.modalDismiss();
            if (this.routeFromSelectAddress === true) {
                this.router.navigate(['order-summary']);
            }
            else {
                if (this.pageType === 'modal') {
                    this.modalController.dismiss({ close: false });
                }
                else {
                    this.presentAlert(`${this.NEW_ADDRESS_LABELS['address_edited_successfully']}`, true);
                }
            }
        });
        this.events.subscribe('user:errorInGettingAddress', () => {
            this.loading.dismiss();
            this.presentAlert(`${this.NEW_ADDRESS_LABELS['error_in_getting_address']}`);
        });
        this.events.subscribe('user:addressValueFromLatLng', (response) => {
            this.loading.dismiss();
            //// console.log('address', response);
            this.getAddressFromResponse(response.results[0]);
        });
    }
    getAddressFromResponse(data) {
        if (!this.editAddressData) {
            this.addressObj.address = data.formatted_address;
            this.addressFromApi = data.formatted_address;
            for (let index = 0; index < data.address_components.length; index++) {
                for (let x = 0; x < data.address_components[index].types.length; x++) {
                    if (data.address_components[index].types[x] === "postal_code") {
                        this.addressObj.pincode = data.address_components[index].long_name;
                    }
                }
            }
        }
        else {
            this.editAddressData.address = data.formatted_address;
            this.addressFromApi = data.formatted_address;
            for (let index = 0; index < data.address_components.length; index++) {
                for (let x = 0; x < data.address_components[index].types.length; x++) {
                    if (data.address_components[index].types[x] === "postal_code") {
                        this.editAddressData.pincode = data.address_components[index].long_name;
                    }
                }
            }
        }
    }
    onClickSaveAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.addressObj.name === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_name']}`);
            }
            else if (this.addressObj.address === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_address']}`);
            }
            else if (this.addressObj.city === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_city']}`);
            }
            else if (this.addressObj.state === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_state']}`);
            }
            else if (this.addressObj.pincode === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_pincode']}`);
            }
            else if (this.addressObj.flatNo === '') {
                this.presentAlert('please enter Flat No');
            }
            else if (this.addressObj.phoneNo === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_phoneno']}`);
            }
            else {
                yield this.presentLoading();
                this.addressObj.createdAt = new Date();
                if (this.addressFromApi === this.addressObj.address) {
                    this.addressObj.lat = this.latitude;
                    this.addressObj.lng = this.longitude;
                }
                this.events.publish('user:saveNewAddress', this.addressObj, this.addressType);
                //this.modalDismiss();
            }
        });
    }
    onClickEditAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.editAddressData.name === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_name']}`);
            }
            else if (this.editAddressData.address === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_address']}`);
            }
            else if (this.editAddressData.city === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_city']}`);
            }
            else if (this.editAddressData.state === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_state']}`);
            }
            else if (this.editAddressData.pincode === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_pincode']}`);
            }
            else if (this.editAddressData.pincode === '') {
                this.presentAlert(`${this.NEW_ADDRESS_LABELS['please_enter_pincode']}`);
            }
            else if (this.editAddressData.flatNo === '') {
                this.presentAlert('please enter Flat No');
            }
            else {
                yield this.presentLoading();
                this.editAddressData.createdAt = new Date();
                if (this.addressFromApi === this.editAddressData.address) {
                    this.editAddressData.lat = this.latitude;
                    this.editAddressData.lng = this.longitude;
                }
                this.events.publish('user:editSavedAddress', this.editAddressData, this.addressType);
            }
        });
    }
    updateNewAddressDefaultStatus() {
        if (this.addressObj.defaultAddress === true || this.addressObj.defaultAddress === null) {
            this.addressObj.defaultAddress = false;
        }
        else {
            this.addressObj.defaultAddress = true;
        }
    }
    updateEditAddressDefaultStatus(status) {
        if (status === true) {
            this.editAddressData.status = false;
        }
        else {
            this.editAddressData.status = true;
        }
    }
    goToPage(page) {
        this.router.navigate([page]);
    }
    addressChange() {
        this.editAddressData.lat = null;
        this.editAddressData.lng = null;
    }
    openStateModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: StatesModalPage,
                cssClass: 'custom-modal'
            });
            modal.onDidDismiss()
                .then((res) => {
                //// console.log('data from modal', res);
                if (res.data) {
                    //// console.log(res.data);
                    if (!this.editAddressData) {
                        this.addressObj.state = res.data.state;
                        this.addressObj.stateCode = res.data.code;
                    }
                    else {
                        this.editAddressData.state = res.data.state;
                        this.editAddressData.stateCode = res.data.code;
                    }
                }
            });
            yield modal.present();
        });
    }
    presentAlert(msg, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                buttons: [{
                        text: 'Ok',
                        handler: () => {
                            //// console.log('Confirm Okay');
                            if (action === true) {
                                this.router.navigate(['user-addresses']);
                                this.modalDismiss();
                            }
                        }
                    }]
            });
            yield alert.present();
        });
    }
    isPincodeValid() {
        const pincode = this.editAddressData ? this.editAddressData.pincode : this.addressObj.pincode;
        if (this.configService.environment.currencyCode === 'INR' && pincode.length && pincode.length < 6) {
            return false;
        }
        else {
            return true;
        }
    }
    getPincodeHeading() {
        if (this.configService.environment.currencyCode === 'INR') {
            return 'NEW_ADDRESS.pincode';
        }
        else {
            return 'NEW_ADDRESS.state_area_code';
        }
    }
    closeModal() {
        this.modalController.dismiss({ closed: true });
    }
    removeSubscriptions() {
        this.events.unsubscribe('user:newAddressSaved');
        this.events.unsubscribe('user:addressEditSuccess');
        this.events.unsubscribe('user:errorInGettingAddress');
        this.events.unsubscribe('user:addressValueFromLatLng');
    }
    showMapModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.askLocationPermission();
            const modal = yield this.modalController.create({
                component: MapPage,
                cssClass: 'custom-modal'
            });
            modal.onDidDismiss()
                .then((res) => {
                if (res.data) {
                    if (!this.editAddressData) {
                        console.log('address:', res.data);
                        this.addressObj.address = res.data;
                    }
                    else if (this.editAddressData) {
                        this.editAddressData.address = res.data;
                    }
                }
            });
            yield modal.present();
        });
    }
    // askLocationPermission(){
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     console.log('position:', position);
    //     // this.lat = position.coords.latitude;
    //     // this.lng = position.coords.longitude;
    //     // setTimeout(() => {
    //     //   this.loadMap();
    //     // }, 500);
    //   })
    // }
    onChangeCountry(e) {
        this[this.editAddressData ? 'editAddressData' : 'addressObj'].country = e.target.value;
    }
    phoneNumberValidation(event, type) {
        const pattern = /[0-9.,+]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (type === 'new') {
            if (!pattern.test(inputChar) || this.addressObj.phoneNo.length >= 13) {
                event.preventDefault();
            }
        }
        else if (type === 'edit') {
            if (!pattern.test(inputChar) || this.editAddressData.phoneNo.length >= 13) {
                event.preventDefault();
            }
        }
    }
    textValidation(event) {
        const pattern = /[a-zA-Z]+$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }
}
